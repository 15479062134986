<template>
  <div v-if="event && race">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <v-card-title v-if="race.route || true" class="headline">
        <span class="mr-4">{{$t('events.race.leaderboard')}}</span>
        <span v-if="meta && meta.total_count" class="" style="font-size:70%;">({{meta.total_count}} {{$t('events.race.results-label')}})</span>
      </v-card-title>
      <RaceResultFilter :meta="meta" :event="event" :race="race" :title="$t('events.race.title')" :showRaceDropdown="false" :filter="resultFilter" @change="updateResults" ref="filter"/>
      <RaceResultsMap ref="resultMap" :event="event" :race="race" :badges="badges" :meta="meta" @showResultDetails="openResultPopup" @showBadgeDetails="openBadgeDetails"/>
      <vue-markdown v-if="race.result_desc" class="markdown mx-4" :html="false" :source="race.result_desc" />
      <RaceResultsGrid :event="event" :race="race" :results="results" :meta="meta" :filter="resultFilter" @search="updateSearch"/>
      <v-pagination 
        v-if="meta && meta.total_count > 50 && !hasFilter" 
        v-model="resultPage" 
        circle 
        :length="Math.ceil(meta.total_count / 50)" 
        @input="loadPage"
        />
    </v-card>
  </div>
</template>

<script>
import eventService from "@/services/eventService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import RaceResultFilter from "@/components/RaceResultFilter.vue";
import RaceResultsGrid from "@/components/RaceResultsGrid.vue";
import RaceResultsMap from "@/components/RaceResultsMap.vue";
import RaceResultDetailsDialog from "@/components/RaceResultDetailsDialog";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RaceResults",
  components: {
    VueMarkdown,
    RaceResultsGrid,
    RaceResultsMap,
    RaceResultFilter,
    RaceResultDetailsDialog,
  },
  props: {
  },
  data() {
    return {
      event: null,
      races: null,
      race: null,
      meta: null,
      markers: null,
      results: null,
      resultFilter: null,
      resultPage: 1,
      badges: null,
      showPointInfo: false,
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      var id = this.$route.params.id;
      var raceId = this.$route.params.raceId;
      this.event = (await eventService.get(id)).data;
      this.race = this.event.races.find(x => x.id === raceId);
      this.results = (await eventService.getRaceResults(id, raceId)).data.data;
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'race', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'results', disabled: true },
      ];
    },
  },

};
</script>
<style lang="scss">
</style>

